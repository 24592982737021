<template>
  <div class="news">
    <head-nav></head-nav>
    <about-new-img></about-new-img>
    <about-nav
      :aboutBreadcrumb="breadcrumb"
      :toback-list="breadcrumbFirst"
    ></about-nav>

    <div class="wrap">
      <h3 class="news-title">
        <img
          :src="$t('about.title-8')"
          alt=""
        />
      </h3>
      <div class="news-option">
        <span>
          <i
            class="iconfont icon-zuobianjiantou"
            @click="onPlusYearClick"
          ></i>
          {{currentYear}}
          <i
            class="iconfont icon-youbianjiantou"
            @click="onMinusYearClick"
          ></i></span>
      </div>
      <div class="news-line"></div>

      <div class="news-box">
        <div
          class="news-list"
          v-for="(item, index) in list"
          :key="index"
          @click="tolink(item.id)"
        >

          <div class="list-imgs">
            <img
              :src="item.image.publicUrl"
              alt=""
            />
          </div>
          <div class="list-content">
            <h4 class="list-title">{{ item.title }}</h4>
            <p
              class="list-text"
              v-html="item.abstract"
            ></p>
          </div>
        </div>
      </div>

      <div class="new-nav-link">
        <span
          v-for="index in pageCount"
          :key="index"
          class="list-link"
          :class="{
            'list-link-active': index === pagination.page
            }"
          @click="pagination.page = index"
        ></span>
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<script>
import gql from 'graphql-tag';

import returnImg from '@/assets/image/product/fanhui.png';

import '@/assets/icon/iconfont.css';

import dayjs from 'dayjs';
// 处理日期的库
import HeadNav from './head-nav';
import AboutNewImg from './about-news-img';
import Footers from './footers';
import AboutNav from './about-nav';

export default {
  components: {
    HeadNav,
    AboutNewImg,
    Footers,
    AboutNav,
  },
  apollo: {
    yearRange: {
      // 请求数据
      query: gql`
        query {
          miniYear: allPosts(orderBy: "createdAt_ASC", first: 1) {
            createdAt
          }
          maxYear: allPosts(orderBy: "createdAt_DESC", first: 1) {
            createdAt
          }
        }
      `,
      update(value) {
        return {
          miniYear: value.miniYear[0].createdAt,
          maxYear: value.maxYear[0].createdAt,
        };
      },
    },
    postResponse: {
      query: gql`
        query($miniYear: DateTime!, $maxYear: DateTime!, $skip: Int!, $first: Int!) {
          # 请求参数和类型
          allPosts(
            where: { createdAt_gte: $miniYear, createdAt_lte: $maxYear }
            skip: $skip
            first: $first
          ) {
            id
            title
            abstract
            image {
              publicUrl
            }
          }
          _allPostsMeta(where: { createdAt_gte: $miniYear, createdAt_lte: $maxYear }) {
            count
          }
        }
      `,
      variables() {
        const { page, pageSize } = this.pagination;
        return {
          miniYear: this.queryRange.min,
          maxYear: this.queryRange.max,
          skip: (page - 1) * pageSize,
          first: pageSize,
          // 计算第一页页脚和最后一页页脚
        };
      },
      skip() {
        return !this.currentYear;
      },
      update(value) {
        return value;
      },
    },
  },
  data() {
    return {
      breadcrumb: [
        {
          label: 'about.news',
        },
      ],
      breadcrumbFirst: [
        {
          path: '/about',
          label: 'toback',
          image: returnImg,
        },
      ],
      currentYear: 0,
      // 初始化的年份
      yearRange: {
        miniYear: '',
        // 最小的年份
        maxYear: '',
        // 最大的年份
      },
      postResponse: {
        allPosts: null,
        // 请求的postResponse里面的allPosts
        total: 0,
      },
      pagination: {
        page: 1,
        // 页脚第一页
        pageSize: 10,
        // 一页显示几条数据
      },
    };
  },
  watch: {
    maxYear() {
      this.currentYear = this.maxYear;
      // 监听当前最大的年份
    },
    currentYear() {
      this.pagination = {
        page: 1,
        pageSize: 10,
        // 一页显示几条数据
      };
    },
  },
  computed: {
    list() {
      if (this.postResponse.allPosts) {
        return this.postResponse.allPosts;
      }
      return [];
      // 创建一个新数组
    },
    total() {
      // eslint-disable-next-line no-underscore-dangle
      const total = this.postResponse?._allPostsMeta?.count;
      if (total) {
        return total;
      }
      return 0;
      // 判断当前页脚
    },
    pageCount() {
      return Math.ceil(this.total / this.pagination.pageSize);
      // 计算当前的分页按钮
    },
    miniYear() {
      if (this.yearRange.miniYear) {
        return dayjs(this.yearRange.miniYear).year();
      }
      return '';
      // 判断最小的年份
    },
    maxYear() {
      if (this.yearRange.maxYear) {
        return dayjs(this.yearRange.maxYear).year();
      }
      return '';
      // 判断最大的年份
    },
    queryRange() {
      const { currentYear } = this;
      if (currentYear) {
        const currentYearDate = dayjs(currentYear.toString(), 'YYYY');
        return {
          min: currentYearDate.startOf('year').toISOString(),
          max: currentYearDate.endOf('year').toISOString(),
        };
      }
      return null;
    },
    // 处理年份的日期
  },
  methods: {
    tolink(id) {
      this.$router.push({
        name: 'about-news-details',
        params: {
          id,
        },
      });
    },
    onPlusYearClick() {
      if (this.currentYear < this.maxYear) {
        this.currentYear += 1;
      }
      // 加年份
    },
    onMinusYearClick() {
      if (this.currentYear > this.miniYear) {
        this.currentYear -= 1;
      }
      // 减年份
    },
  },
};
</script>

<style lang="scss" scoped>
.news {
  .wrap {
    padding: 88px 0 150px 0;

    .news-title {
      text-align: center;
      margin-top: 30px;
    }

    .news-option {
      text-align: center;
      margin: 16px 0 50px 0;

      span {
        font-size: 36px;
        font-weight: 900;
        display: inline-block;
        position: relative;

        .iconfont {
          display: inline-block;
          position: relative;
          top: -6px;
          margin: 0 18px;
          font-size: 24px;
          font-weight: 600;
          color: #666;
          cursor: pointer;
        }
      }
    }

    .news-line {
      width: 100%;
      height: 1px;
      background: #eee;
      box-shadow: 10px 3px 53px 9px rgba(0, 0, 0, 0.1);
    }

    .news-box {
      width: 100%;
      padding: 40px 0 100px 0;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      justify-content: space-between;

      .news-list {
        margin-top: 44px;
        width: 580px;
        height: 150px;
        border: 1px solid #eee;
        cursor: pointer;

        &:hover {
          .list-imgs {
            img {
              max-width: 100%;
              transform: scale(1.2);
            }
          }
        }

        .list-imgs {
          float: left;
          width: 230px;
          height: 150px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            max-width: 100%;
            transition: all 500ms;
          }
        }

        .list-content {
          float: left;
          width: 310px;
          margin: 10px 20px;
          .list-title {
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            font-size: 16px;
            margin-top: 10px;
            color: #6b6b6b;
          }

          .list-text {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
            font-size: 14px;
            line-height: 20px;
            margin-top: 16px;
          }
        }
      }
    }

    .new-nav-link {
      text-align: center;

      .list-link {
        display: inline-block;
        margin: 0 5px;
        width: 10px;
        height: 10px;
        background: #999999;
        cursor: pointer;

        &:hover {
          background: #ffbf00;
        }
      }

      .list-link-active {
        background: #ffbf00;
      }
    }
  }
}
</style>
